.chatAdmin :global {
  .chatMessageList {
    height: 80vh;
    overflow: auto;
    background-color: #fff;
    .messageList {
      .message {
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;

        .userImage {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          overflow: hidden;
        }

        .userInfo {
          flex: 2;
        }
      }
    }
  }

  .chatView {
    height: 80vh;

    .userInfo {
      .userImage {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .chatContent {
      width: 100%;
      height: 350px;
      overflow: auto;

      .chatMessage {
        margin-bottom: 15px;
        .userImage {
          width: 35px;
          height: 35px;
          padding: 5px;
          border: 2px solid;
          border-radius: 50%;
        }

        .userMessage {
          color: #fff;
          padding: 10px;
          border-radius: 10px;
          flex: 2;
        }
      }
    }

    .chatViewFooter {
      justify-content: flex-end;
      display: flex;
      width: 100%;
      margin-top: 20px;
      border-top: 1px solid #f5f5f5;
      padding: 20px;
      position: relative;

      .onUploadImages {
        position: absolute;
        width: 82%;
        height: 100%;
        left: 0;
        left: 50%;
        right: 0;
        z-index: 99;
        transform: translateX(-50%);
        overflow: auto;

        ul {
          li {
            position: relative;
            width: fit-content;
            img {
              max-width: 60px;
            }

            .icon {
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
