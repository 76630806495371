.studentChat :global {
  .chat {
    position: fixed;
    right: 23px;
    bottom: 19px;
    z-index: 20;


    .chatOpen {
      left: -360px;
      position: absolute;
      transition: 1s;
    }

    .chatClose {
      position: absolute;
      left: 100px;
      transition: 1s;
    }
  }

  .iconParent {
    width: 55px;
    height: 55px;
    z-index: 4000;
    bottom: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    position: relative;


    .tooltipChat {
      position: absolute;
      left: -269px;
      background: #333;
      border: none;
      padding: 2px 13px;
      border-radius: 10px;
      min-width: 257px;
    }

    svg {
      font-size: 25px;
    }
  }

  .chatParent {
    background: #fff;
    border-radius: 11px;
    width: 423px;
    position: absolute;
    min-height: 300px;
    overflow: hidden;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    bottom: 66px;
    left: -18px;

    @media (max-width: 768px) {
      width: 300px;
      left: 110px;
    }

    .typeItems {
      padding: 20px;
      padding-top: 30px;
    }

    .studentChatHeader {
      white-space: pre-wrap;
      background: white;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      color: #121212;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 20px;

      p {
        font-weight: 700;
        font-size: 14px;
        color: #121212;
        background: #fafafa;
        border-radius: 30px;
        padding: 8px;
        width: 100%;
        text-align: right;
      }
    }

    .studentChatContent {
      width: 100%;
      min-height: 232px;
      max-height: 350px;
      overflow: auto;

      .chatMessage {
        margin-bottom: 15px;

        .userImage {
          width: 35px;
          height: 35px;
          padding: 5px;
          border: 2px solid;
          border-radius: 50%;
        }

        .userMessage {
          color: #fff;
          padding: 10px;
          border-radius: 10px;
          flex: 2;
        }
      }
    }

    .studentChatFooter {
      justify-content: flex-end;
      display: flex;
      width: 100%;
      border-top: 1px solid #f5f5f5;
      padding: 20px;

      .onUploadImages {
        position: absolute;
        width: 77%;
        height: 100%;
        left: 0;
        left: 50%;
        right: 0;
        z-index: 99;
        transform: translateX(-50%);
        overflow: auto;

        ul {

          li {
            position: relative;
            width: fit-content;

            img {
              max-width: 100px;
            }

            .icon {
              position: absolute;
              top: 2px;
              right: 2px;
              cursor: pointer;
              font-size: 18px;
              background-color: #00c1ff;
              border-radius: 50%;
              color: #fff;
              padding: 2px;
            }
          }
        }
      }
    }
  }
}