@import url('https://fonts.googleapis.com/css2?family=Changa:wght@400;600;800&display=swap');

:root {
  --blue: #00d8fc;
  --light: #eef2ff;
  --light-sky: #a0acf3;
  --yellow: #fdd816;
  --orange: #ef7a37;
  --red: #e72b5b;
  --sky: #3bbff0;
  --white: #ffffff;
  --black: #292828;
  --lght: #e9e0e0;
  --purple: #5f37ef;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: var(--blue);
  background-color: var(--white);
  font-size: 1.6rem;
  z-index: 10;
}

main {
  background-color: var(--white);
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
}

.vid {
  width: 800px;
  height: 200px;
}

.width {
  width: min(90%, 1300px);
  margin-inline: auto;
}

.fixed-header {
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 50;
  background-color: var(--blue);
  box-shadow: 3px 3px 12.5px 7px rgba(23, 199, 252, 0.25);
}

.header {
  width: 90%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 1rem;
  gap: 2rem;
}

.header__img-container a {
  cursor: pointer;
}

.logo {
  width: 6rem;
  height: auto;
}

.burger {
  width: 4rem;
  height: auto;
  transition: all 0.3s ease-in-out;
  display: none;
  cursor: pointer;
  color: var(--light);

}

.burger:hover {
  transform: scale(1.1);
}

.fixed-mobile-nav {
  position: fixed;
  top: 80px;
  right: 100%;
  display: none;
  z-index: 150;
  width: 100%;
  height: calc(100vh - 80px);
  transition: all 0.3s ease-in-out;
}

.mobile__nav {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--blue);
  font-weight: 600;
  color: var(--light);

}

.mobile__nav li a {
  transition: all 0.3s ease-in-out;
}

.mobile__nav li a:hover {
  color: var(--light-sky);
}

.toggle-mobile-nav {
  right: 0;
}

.header__nav--ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.header__nav--ul li a {
  font-weight: 600;
}

.header__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__buttons button {
  background-color: transparent;
  padding: 1rem;
  border: 3px solid var(--light);
  color: var(--light);
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 3rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.header__buttons button:hover {
  background-color: transparent;
  border: 3px solid var(--light-sky);

  color: var(--light-sky);
}

.header__nav--ul li a {
  transition: all 0.3s ease-in-out;
  color: var(--light);
}

.header__nav--ul li a:hover {
  color: var(--light-sky);
}

main {
  width: 100%;
}

/**Hero*********************************/

.hero-container {
  width: 100%;
  background-image: var(--light);
  min-height: calc(100vh - 80px);
  height: fit-content;
}

.hero {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  height: 100%;
  margin-top: 80px;
  width: 100%;
  padding-top: 5rem;
  width: min(90%, 1300px);
  margin-inline: auto;
}

.hero__vertical {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__horizontal {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.hero__horizontal--left {
  width: 60%;
  height: 100%;
  padding-block: 2rem;
}

.hero__horizontal--right {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.overflow-hidden {
  overflow: hidden;
}

.vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.vertical-text-h2 {
  color: var(--black);
  font-size: 3rem;
  font-weight: bold;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.title {
  color: var(--black);
  font-weight: bold;
  font-size: clamp(2rem, 3.5vw, 5rem);
  letter-spacing: 2rem;
  line-height: 1;
}

.sub-title-outer-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.sub-title-container {
  width: 40%;
}

.sub-title {
  font-size: clamp(2rem, 2.5vw, 3rem);
  font-weight: bold;
  text-align: start;
  width: 100%;
  color: var(--black);
}

.video-cta {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.paragraph-container {
  width: 100%;
  margin-block: 2rem;
}

.paragraph {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--black);
}

.video-fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #a0acf3d1;
  z-index: 110;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.actual-video-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
}

.hide-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: #e72b5a00;
}

.actual-video-container {
  max-width: 800px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  border-radius: 2rem;
  overflow: hidden;
  transform: scale(0);
  outline: none;
  transition: all 0.3s ease-in-out;
  z-index: 150;
  border: 1rem solid var(--blue);
  cursor: pointer;
}

.actual-video-container video {
  cursor: pointer;
}

.show-video-fixed {
  pointer-events: all;
  opacity: 1;
}

.show-video {
  transform: scale(1);
}

.video-container {
  max-width: 550px;
  height: 250px;
  width: 100%;
  border-radius: 5rem;
  border: 1rem solid var(--blue);
  overflow: hidden;
  position: relative;
}

.video-wrapper-absolute {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}

.watch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.watch:hover {
  color: var(--light);
}

.play-icon {
  width: 5rem;
  height: 5rem;
}

.CTA-Container button {
  background-color: transparent;
  padding: 1rem;
  border: 3px solid var(--light);
  color: var(--light);
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 2rem;
}

.CTA-Container button:hover {
  background-color: transparent;
  color: var(--blue);
  border: 3px solid var(--blue);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.right-sub-title {
  font-size: 3rem;
  font-weight: bold;
  text-align: end;
  margin-top: 2rem;
  color: var(--blue);
}

.absolute1 {
  position: absolute;
  top: 11%;
  right: 11%;
  width: 40rem;
  opacity: 0.3;
}

.tracker {
  position: fixed;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid var(--blue);
  pointer-events: none;
  mix-blend-mode: difference;
  opacity: 0;
  transition: top 0.075s, left 0.075s, opacity 0.5s;
}

.absolute2 {
  position: absolute;
  bottom: 5%;
  left: 1%;
  width: 12rem;
  opacity: 0.4;
}

.absolute3 {
  position: absolute;
  top: 13%;
  left: 7%;
  width: 11rem;
  opacity: 0.5;
}

.common-animation {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
  animation-delay: var(--delay);
}

.to-right,
.to-bottom,
.to-left {
  display: block;
  opacity: 0;
}

.scale-absolute-img {
  transform: scale(0);
  animation-name: ScaleImgAbsolute;
}

.to-right {
  animation-name: toRight;
}

.to-bottom {
  animation-name: toBottom;
}

.to-left {
  animation-name: toLeft;
}

.scale-rotate {
  scale: 0;
  animation-name: scaleRotate;
  animation-duration: 0.5s;
}

/* Keyframes */

@keyframes ScaleImgAbsolute {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes toRight {
  from {
    opacity: 1;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes toLeft {
  from {
    opacity: 1;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes toBottom {
  from {
    opacity: 1;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes scaleRotate {
  from {
    scale: 0;
    rotate: 60deg;
  }

  to {
    scale: 1;
    rotate: 0deg;
  }
}

.CTA-Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
}

.Video__title {
  font-weight: bold;
  font-size: 3rem;
  margin-top: 5rem;
}

/**Features*****************************/

.features {
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.features h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--blue);
}

.feature {
  gap: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  height: 400px;
  position: relative;
}

.arrow {
  position: absolute;
  max-width: 15rem;
  bottom: -250px;
  left: 47%;
  transform: translateX(-50%);
  transform: rotate(40deg);
  opacity: 0.1;
}

.arrow-flip {
  position: absolute;
  max-width: 15rem;
  bottom: -250px;
  left: 43%;
  transform: translateX(-50%);
  transform: rotate(-40deg);
  opacity: 0.1;
}

.feature-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-img img {
  cursor: pointer;
  max-width: 400px;
  width: 100%;
  max-height: 400px;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: 11px -11px 0 0 var(--blue);
  transition: all 0.3s ease-in-out;
}

.feature-img img:hover {
  box-shadow: 0px 0px 0 0 var(--blue);
}

.feature-content-container {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-content {
  max-width: 400px;
  height: 400px;
  padding-inline: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
}

.feature-content:hover {
  background-color: var(--blue);
  color: var(--light);
}

.feature-content:hover h2 {
  color: var(--light);
}

.feature-img,
.feature-content-container {
  width: 50%;
}

.feature-content h2 {
  margin-bottom: 3rem;
  font-size: 3rem;
}

.feature2 {
  flex-direction: row-reverse;
}

.feature4 {
  flex-direction: row-reverse;
}

.feature6 {
  flex-direction: row-reverse;
}

/**Testimonials*/

.testimonials {
  margin-top: 10rem;
  padding: 5rem;
  height: auto;
}

.testimonials h2,
.courses>h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
  color: var(--blue);
}

.testimonials-slide {
  width: 500px;
  height: 500px;
  border: 10px solid var(--blue);
  object-fit: cover;
  display: block;
  border-radius: 1rem;
  margin-block: 8rem;
  transition: all 0.3s ease-in-out;
}

.testimonials-slide:hover {
  transform: scale(1.1);
}

.testimonials-button-left,
.testimonials-button-right,
.courses-button-left,
.courses-button-right {
  position: absolute;
  top: 3rem;
  transform: translateY(-50%);
  border: 4px solid var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 15;
  cursor: pointer;
}

.testimonials-button-left,
.courses-button-left {
  left: 0;
}

.testimonials-button-right,
.courses-button-right {
  right: 0;
}

.testimonials-left-arrow,
.testimonials-right-arrow {
  width: 4rem;
  height: 4rem;
  color: var(--blue);
  transition: all 0.4s ease-in-out;
}

.testimonials-left-arrow:hover,
.testimonials-right-arrow:hover {
  transform: rotate(360deg);
}

/**courses----------------------------*/

.courses {
  margin-block: 5rem;
  padding: 5rem;
  height: auto;
}

.swiper-card {
  width: 350px;
  height: 450px;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid var(--blue);
  border-radius: 2rem;
  padding: 1rem;
}

.course-img {
  height: 250px;
  width: 100%;

  display: block;
  object-fit: cover;
  border-radius: 2rem 2rem 0 0;
}

.course-content {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  background-color: var(--light);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
}

.course-content--above,
.course-content--down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.course-description {
  font-size: 1.3rem;
  text-align: center;
}

.course-content--down a {
  background-color: var(--blue);
  color: var(--light);
  border: 2px solid var(--light);
  padding: 0.5rem 1.3rem;
  border-radius: 3rem;
  transition: all 0.3s ease-in-out;
}

.course-content--down a:hover {
  color: var(--blue);
  background-color: var(--light);
  border: 2px solid var(--blue);
}

/**Footer*/

footer {
  background-color: var(--black);
  width: 100%;
  padding-top: 2rem;
  color: var(--light-sky);

}

.footer {
  z-index: -100;
  padding-block: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  place-content: center;
  align-items: center;
  gap: 5rem;
}

.footer__above {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  border-bottom: 2px solid var(--light-sky);
  padding-bottom: 2rem;
  margin-block: 2rem;
}

.footer div h2 {
  color: var(--light);
  font-weight: 600;
  border-bottom: 2px solid var(--light);

  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
}

.footer__above--social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.footer__above--social a img {
  width: 4rem;
  transition: all 0.3s ease-in-out;

}

.whatsapp {
  width: 3.1rem;

}

.facbook {
  width: 5.3rem;
  height: auto;

}

.footer-bottom {
  text-align: center;

}


.footer__above--social a img:hover {
  opacity: 0.7;
}

.footer div ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.footer-logo {
  font-weight: 600;
  color: var(--light);
  font-size: 2rem;
  letter-spacing: 0.5rem;
}


.footer-bottom {
  border-top: 2px solid var(--light-sky);
  padding-block: 2rem;

}

.footer-bottom a {
  color: var(--light);
}





/**Media Quires*/

@media screen and (max-width: 81em) {

  .arrow,
  .arrow-flip {
    display: none;
  }

  .testimonials-slide {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 64em) {
  .testimonials-slide {
    width: 350px;
    height: 350px;
  }

  .footer {
    grid-template-columns: repeat(3, 1fr);
  }

  .hero__vertical {
    display: none;
  }

  .hero__horizontal {
    width: 100%;
  }

  .hero__horizontal--left {
    width: 50%;
  }

  .hero__horizontal--right {
    width: 50%;
  }

  .hero-container {
    height: fit-content;
  }

  .absolute2 {
    display: none;
  }




}

@media screen and (max-width: 55em) {
  .testimonials-slide {
    margin-inline: auto;
    width: 350px;
  }
}

@media screen and (max-width: 50em) {
  .hero {
    height: fit-content;
  }

  .header__nav {
    display: none;
  }

  .hero__horizontal--left {
    width: 100%;
  }

  .hero__horizontal--right {
    display: none;
  }

  .burger {
    display: block;
  }

  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .actual-video-container {
    max-width: 400px;
    max-height: 300px;
  }

  .hero__horizontal {
    flex-direction: column;
    gap: 2rem;
  }

  .CTA-Container {
    justify-content: center;
    gap: 5rem;
  }

  .video-cta {
    align-items: center;
  }

  .paragraph {
    text-align: center;
  }

  .fixed-mobile-nav {
    display: block;
  }

  .absolute1 {
    display: none;
  }

  .feature {
    flex-direction: column;
    height: fit-content;
  }

  .feature-img,
  .feature-content-container {
    width: 100%;
  }

  .feature-content-container {
    height: fit-content;
  }

  .feature-content {
    height: fit-content;
    padding: 2rem;
  }

  .feature {
    gap: 4rem;
  }

  .feature-content p {
    text-align: center;
  }

  .testimonials-button-left,
  .testimonials-button-right,
  .courses-button-left,
  .courses-button-right {
    display: none;
  }

  .footer__above {
    flex-direction: column;
  }
}

@media screen and (max-width: 33em) {
  .footer {
    grid-template-columns: repeat(1, 1fr);
  }
}