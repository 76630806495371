// @import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;800&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@400;600;800&display=swap');
//fonts
$heading: "Arapey", serif;
$body: "Maitree", serif;
$menu: "Inter", sans-serif;
//colors

:root {
  $purple: #7664ff;
  $light-purple: #bdadfc;
  $dark-purple: #77298c;
  $pink: #f1ccfa;
  $blue: #0c145e;
  $light-gray: #eaebf5;
  $dark-gray: #b2b8e8;

  $rose: #f1468e;
  $light-rose: #df90bd;
  $bg: rgb(255, 201, 169);
  $white: #ffffff;
  $black: #000000;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: "Cairo", sans-serif;
  font-family: 'Changa', sans-serif !important;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  //background-color: #f3f4f6;
  //color:#7664ff ;
  font-size: 1.6rem;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.width {
  width: min(90%, 1300px);
  margin-inline: auto;
}

.buttonS1 {
  cursor: pointer;
  position: relative;
  padding: 8px 70px;
  font-size: 18px;
  color: #4285f4;
  border: 2px solid #4285f4;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 600;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 70px;
    height: 50px;
    border-radius: inherit;
    scale: 0;
    z-index: -1;
    background-color: #0b9d58;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover::before {
    scale: 3;
  }

  &:hover {
    color: #fff;
    scale: 1.1;
    box-shadow: 0 0px 20px #0b9d58;
  }

  &:active {
    scale: 1;
  }
}