@tailwind base;
@tailwind components;
@tailwind utilities;

table .ant-checkbox-wrapper {
  justify-content: center;
}

.ant-picker-dropdown {
  width: 100% !important;
  max-width: 400px;
  min-width: 200px;
}

@media (max-width: 768px) {
  .ant-picker-dropdown {
    max-width: 100%;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .ant-picker-dropdown {
    max-width: 95%;
    padding: 10px;
  }
}

.quiz,
.homework,
.exam {
  position: relative; /* Ensure the element is positioned */
  margin-bottom: 20px; /* Space out sections */
}

.joyride-tooltip {
  z-index: 10000 !important; /* Ensure the tooltip is above the navbar */
  margin-top: 60px !important; /* Adjust this value based on your navbar height */
}
