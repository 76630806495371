//!---------------Admin------------------------

//!-----------------Dashboard--------

.dash-menu-items a.active {
  background-color: #ffce07;
  color: #fef2f2;

  span {
    background-color: #fef2f2;
  }
}

.draggable {
  transition: transform 0.15s ease-in-out;
}

//!----dash burger mobile header

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 40px;
  cursor: pointer;
  margin: auto;
  display: block;
  height: calc(4px * 3 + 11px * 2);
}

.bar {
  position: absolute;
  left: 0;
  right: 0;
  height: 4px;
  border-radius: calc(4px / 2);
  background: #fef2f2;
  color: inherit;
  opacity: 1;
  -webkit-transition: none 0.35s cubic-bezier(0.5, -0.35, 0.35, 1.5) 0s;
  transition: none 0.35s cubic-bezier(0.5, -0.35, 0.35, 1.5) 0s;
}

/***** Spin Animation *****/

.bar--top {
  bottom: calc(50% + 11px + 4px / 2);
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
  -webkit-transition-delay: calc(0s + 0.35s), 0s;
  transition-delay: calc(0s + 0.35s), 0s;
}

.bar--middle {
  top: calc(50% - 4px / 2);
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-delay: calc(0s + 0.35s);
  transition-delay: calc(0s + 0.35s);
}

.bar--bottom {
  top: calc(50% + 11px + 4px / 2);
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, -webkit-transform;
  transition-property: top, transform;
  transition-property: top, transform, -webkit-transform;
  -webkit-transition-delay: calc(0s + 0.35s), 0s;
  transition-delay: calc(0s + 0.35s), 0s;
}

#checkbox:checked+.toggle .bar--top {
  bottom: calc(50% - 4px / 2);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition-delay: 0s, calc(0s + 0.35s);
  transition-delay: 0s, calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--middle {
  opacity: 0;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: calc(0s + 0.35s);
  transition-delay: calc(0s + 0.35s);
}

#checkbox:checked+.toggle .bar--bottom {
  top: calc(50% - 4px / 2);
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0s, calc(0s + 0.35s);
  transition-delay: 0s, calc(0s + 0.35s);
}

//!--------copy to clipboard button for generated codes
.code-btn {
  border-radius: 0.3rem;
  padding: 0.2rem 0.4rem;
  transition: all 0.3s ease-in-out;
  border: 2px solid #f9b522;
  color: #fef2f2;
  background-color: #f9b522;
}

.code-btn:hover {
  background-color: transparent;
  color: #f9b522;
}

.code-btn:active {
  transform: scale(0.9);
}

//!--------place the sorting arrows besides the column name
.ant-table-column-sorters {
  display: inline-flex !important;
  align-items: center !important;
}

.ant-table-filter-dropdown-btns {
  .ant-btn-primary {
    background-color: rgb(14 165 233);
  }
}

//!---------- visibility-switch ----------

/* The visibility-switch - the box around the visibility-slider */
.visibility-switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.visibility-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The visibility-slider */
.visibility-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fba6a66f;
  border: 2px solid #4285f4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 32px;
  outline: none;
}

.visibility-slider:before {
  position: absolute;
  content: "";
  height: 2em;
  width: 2em;
  border-radius: 50%;
  outline: 2px solid #4285f4;
  left: -1px;
  bottom: -1px;
  background-color: #fef2f2;
  -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: -webkit-transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s;
  transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
}

.slider-icon {
  opacity: 0;
  height: 12px;
  width: 12px;
  stroke-width: 8;
  position: absolute;
  z-index: 999;
  stroke: #fef2f2;
  right: 60%;
  top: 30%;
  -webkit-transition: right ease-in-out 0.3s, opacity ease-in-out 0.15s;
  transition: right ease-in-out 0.3s, opacity ease-in-out 0.15s;
}

.visibility-switch input:checked+.visibility-slider {
  background-color: #fba6a6;
}

.visibility-switch input:checked+.visibility-slider .slider-icon {
  opacity: 1;
  right: 16%;
}

.visibility-switch input:checked+.visibility-slider:before {
  -webkit-transform: translateX(1.5em);
  -ms-transform: translateX(1.5em);
  transform: translateX(1.5em);
  background-color: #4285f4;
}

//!--grid-auto-fit-------

.grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 3rem;
  justify-items: center;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.video-grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 3rem;
  justify-items: center;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

/*single-course---------------------*/
.course-details-wrapper {
  background-image: url("../../public/assets/pattern.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.code-input {
  transition: box-shadow 0.3s ease-in-out;
  border: 2px solid #4285f4;
  color: #4285f4;
}

.code-input::placeholder {
  color: #4285f466;
  opacity: 0.5;
  font-weight: 600;
}

.code-input:focus {
  background-color: #4285f41a;
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #0b9d58;
}

video[poster] {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.w-session {
  width: calc(100% - 25px);
}

.w-lock {
  width: 25px;
}

.teacher-bg-pattern {
  animation: teacherBgPattern 15s linear infinite;
}

@keyframes teacherBgPattern {
  to {
    transform: rotate(360deg);
  }
}